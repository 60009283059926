import React, { useState } from 'react';
import Button from '../Button';

import FormInputField from '../FormInputField/FormInputField';

import * as styles from './Contact.module.css';

const Contact = (props) => {
  const initialState = {
    name: '',
    phone: '',
    email: '',
    comment: '',
  };

  const [contactForm, setContactForm] = useState(initialState);

  const handleChange = (id, e) => {
    const tempForm = { ...contactForm, [id]: e };
    setContactForm(tempForm);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setContactForm(initialState);
  };

  return (
    <div className={styles.root}>
      <div className={styles.section}>
        <h4>Work With Us</h4>
        <p>We look forward to hearing from you.</p>
      </div>

      <div className={styles.contactContainer}>

      <form name='contact' method="post" action="mailto:contact@ardd.tech" id="contact_form" accept-charset="UTF-8" class="newsletter-form">
      <FormInputField name="contact" method="POST" data-netlify="true"
        id="name"
        labelName="Name"
        placeholder="Yusuke Urameshi"
        value={contactForm.name}
        handleChange={handleChange}
        required={true}
       />
      <FormInputField name="contact" method="POST" data-netlify="true" type="number"
        id="phone"
        labelName="Phone"
        placeholder="123-456-7890"
        value={contactForm.phone}
        handleChange={handleChange}
        required={false}
      />
      <FormInputField name="contact" method="POST" data-netlify="true"
        id="email"
        labelName="Email"
        placeholder="you@gmail.com"
        value={contactForm.email}
        handleChange={handleChange}
        required={true}
      />
      <FormInputField name="contact" method="POST" data-netlify="true"
        id="comment"
        labelName="Drop us a line"
        placeholder="I would like to..."
        type="textarea"
        value={contactForm.comment}
        handleChange={handleChange}
        required={true}
      />

      
<Button target={"mailto:contact@ardd.tech"} name="contact" method="POST" data-netlify="true"
            className={styles.customButton}
            level={'primary'}
            type={'buttonSubmit'}onClick={handleSubmit}
            >
            submit
          </Button>
    </form>

      

      </div>
    </div>
  );
};

export default Contact;
